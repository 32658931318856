import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import WebpImage from "../WebpImage";
import LeftImage from '@resources/cam02.jpg?format=webp&quality=75&w=1040;767;500&as=srcset&imagetools';

const InvestmentOnMedal = () => {
    return (
        <section className="mb-26 maxMd:mb-0">
            <Container>
                <Row>
                    <Column xl={8} lg={8}>
                        <h3 className="text-h2 font-extrabold leading-h2 text-secondary uppercase mb-12 maxMd:mb-8">
                            Inwestycja mieszkaniowa na <span className="text-primary">medal</span>
                        </h3>
                    </Column>
                </Row>

                <Row>
                    <Column xl={7} lg={7} className="lg:-ml-26 lg:order-1 order-2 ml-0 lg:!px-3 maxMd:hidden !px-0">
                        <img
                            width={892}
                            height={712}
                            srcSet={LeftImage}
                            loading='lazy'
                            className="h-full w-full maxMd:hidden"
                            alt="Kusociński wizualizacja"
                        />
                    </Column>

                    <Column xl={5} lg={5} className="flex flex-col lg:order-2 order-1 lg:mb-0 mb-12">
                        <div className="xl:pl-20 lg:pl-5 lg:pt-16 relative pl-0 pt-0">
                            <div>
                                <div className="flex gap-x-6 items-end flex-wrap">
                                    <p className="lg:text-[128px] text-secondary/50 lg:leading-[110px] text-[80px] leading-[80px] lg:w-auto w-full maxMd:mb-2">2</p>
                                    <p className="uppercase text-primary font-extrabold text-h2 leading-h2">Budynki</p>
                                </div>
                                <hr className="border-secondary/20 border-[2px] my-12 maxMd:my-6" />
                            </div>
                            <div>
                                <div className="flex xl:flex-nowrap flex-wrap gap-x-6 items-end">
                                    <p className="lg:text-[128px] text-secondary/50 lg:leading-[110px] text-[80px] leading-[80px] maxMd:w-full maxMd:mb-2">312</p>
                                    <p className="uppercase text-primary font-extrabold text-h2 leading-h2">Nowoczesnych mieszkań</p>
                                </div>
                                <hr className="border-secondary/20 border-[2px] my-12" />
                            </div>
                            <div>
                                <div className="flex flex-wrap gap-x-6 items-end">
                                    <div className="flex items-end">
                                        <p className="uppercase text-primary font-extrabold text-h3 leading-h3">od</p>
                                        <p className="lg:text-[128px] text-secondary/50 lg:leading-[110px] text-[80px] leading-[80px] maxMd:w-full">6</p>
                                    </div>
                                    <div className="flex items-end">
                                        <p className="uppercase text-primary mb-auto font-extrabold text-h3 leading-h3">do</p>
                                        <p className="lg:text-[128px] text-secondary/50 lg:leading-[110px] text-[80px] leading-[80px] maxMd:w-full">10</p>
                                    </div>
                                    <p className="uppercase text-primary font-extrabold text-h2 leading-h2 lg:w-auto w-full lg:mt-0 mt-2">Pięter</p>
                                </div>
                            </div>
                            <img src="/storage/static/runner.svg" alt="Biegacz" className="absolute lg:-right-24 top-14 right-0" width={517} height={672} />
                        </div>
                    </Column>

                </Row>
            </Container>
            <img
                width={892}
                height={712}
                loading={'lazy'}
                srcSet={LeftImage}
                className="h-full w-full order-3 maxMd:block hidden"
                alt="Kusociński wizualizacja"
            />
        </section>
    )
}

export default InvestmentOnMedal;