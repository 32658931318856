import { useState } from "react";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import WebpImage from "../WebpImage";
import useMediaQuery from "@/Hooks/useMediaQuery";
import { Icon } from "@iconify/react/dist/iconify.js";

const InvestmentLocation = () => {
    const [loadedMap, setLoadedMap] = useState<string>('/storage/estate-maps/mapa-baza.png');
    const isMobile = useMediaQuery();

    const reloadMap = (path: string): void => {
        setLoadedMap(path);
    }

    return (
        <section id="lokalizacja" className="mb-32 maxMd:mb-14">
            {/* {isMobile &&
                <>
                    <div className="overflow-x-scroll relative">
                        <WebpImage
                            width={1600}
                            height={486}
                            fallback={'/storage/estate-maps/mapa-baza.png'}
                            lazy
                            className="w-[1200px] object-contain max-w-[unset]"
                            alt="Mapa osiedla Kusocińskiego"
                        />
                    </div>
                    <p className="text-p mt-2 mb-6 px-4 text-primary font-bold">Przesuń palcem, aby przesunąć mapę.</p>
                </>
            } */}
            <Container>
                <Row>
                    <Column>
                        <div className="pl-8 maxMd:pl-0">
                            <p className="text-primary text-p leading-p uppercase mb-4 font-extrabold">Lokalizacja</p>
                            <h3 className="text-secondary text-h2 leading-h2 mb-8 font-extrabold uppercase">Ta lokalizacja <br />oszczędza <span className="text-primary">Twój czas</span></h3>
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column xl={7}>
                        <div className="pl-8 maxMd:pl-0">
                            <p className="text-p leading-p text-text mb-6 maxMd:mb-4">Z Kusocińskiego sprawnie dotrzesz także do centrum miasta – zarówno autem, jak i transportem miejskim. Okolica jest świetnie skomunikowana dzięki wielu liniom autobusowych (w tym linii nocnej) oraz PKM (z możliwością przesiadki na SKM), a także łatwości dojazdu do Obwodnicy Trójmiasta czy Trasy Adamowicza.</p>
                            <p className="text-p leading-p text-text mb-12 maxMd:mb-4">Bliskość natury jest tu namacalna za sprawą sąsiedztwa Otuliny Trójmiejskiego Parku Krajobrazowego.</p>
                        </div>
                    </Column>
                </Row>
                {/* {!isMobile && */}
                    <>
                        <Row>
                            <Column>
                                <WebpImage
                                    width={1600}
                                    height={486}
                                    fallback={loadedMap}
                                    lazy
                                    className="w-full h-auto object-cover"
                                    alt="Mapa osiedla Kusocińskiego"
                                />
                            </Column>
                        </Row>
                        <Row className="justify-center -mt-[130px] maxMd:mt-6">
                            <Column>
                                <div className="flex flex-wrap px-8 maxMd:px-0 gap-x-6 gap-y-6 justify-center">
                                    <div className="glass-style rounded-2xl px-4 py-8 text-center max-w-[230px] maxMd:max-w-[160px] w-full">
                                        {/* <Icon icon={`hugeicons:backpack-01`} width={72} className="mb-6 mx-auto text-secondary"/> */}
                                        <img src="/storage/icons/backpack.svg" alt="Ikona 1" className="mb-6 mx-auto" />
                                        <p className="text-text tracking-[0.45px] font-extrabold text-p leading-p mb-2">Szkoła podstawowa i przedszkole:</p>
                                        <p className="text-h3 leading-h3 tracking-[0.6px] font-extrabold text-secondary">250m</p>
                                    </div>
                                    <div className="glass-style rounded-2xl px-4 py-8 text-center max-w-[230px] maxMd:max-w-[160px] w-full">
                                        {/* <Icon icon={`hugeicons:tram`} width={72} className="mb-6 mx-auto text-secondary"/> */}
                                        <img src="/storage/icons/hugeicons_tram.svg" alt="Ikona 1" className="mb-6 mx-auto" />
                                        <p className="text-text tracking-[0.45px] font-extrabold text-p leading-p mb-2">Przystanek tramwajowy:</p>
                                        <p className="text-h3 leading-h3 tracking-[0.6px] font-extrabold text-secondary">230m</p>
                                    </div>
                                    <div className="glass-style rounded-2xl px-4 py-8 text-center max-w-[230px] maxMd:max-w-[160px] w-full">
                                        {/* <Icon icon={`hugeicons:speed-train-01`} width={72} className="mb-6 mx-auto text-secondary"/> */}
                                        <img src="/storage/icons/hugeicons_speed-train-01.svg" alt="Ikona 1" className="mb-6 mx-auto" />
                                        <p className="text-text tracking-[0.45px] font-extrabold text-p leading-p mb-2">Przystanek <br /> PKM:</p>
                                        <p className="text-h3 leading-h3 tracking-[0.6px] font-extrabold text-secondary">1,3 km</p>
                                    </div>
                                    <div className="glass-style rounded-2xl px-4 py-8 text-center max-w-[230px] maxMd:max-w-[160px] w-full">
                                        {/* <Icon icon={`hugeicons:beach`} width={72} className="mb-6 mx-auto text-secondary"/> */}
                                        <img src="/storage/icons/hugeicons_beach.svg" alt="Ikona 1" className="mb-6 mx-auto" />
                                        <p className="text-text tracking-[0.45px] font-extrabold text-p leading-p mb-2">Morze (Molo w Brzeźnie):</p>
                                        <p className="text-h3 leading-h3 tracking-[0.6px] font-extrabold text-secondary">9 km</p>
                                    </div>
                                    <div className="glass-style rounded-2xl px-4 py-8 text-center max-w-[230px] maxMd:max-w-[160px] w-full">
                                        {/* <Icon icon={`hugeicons:shopping-cart-01`} width={72} className="mb-6 mx-auto text-secondary"/> */}
                                        <img src="/storage/icons/hugeicons_shopping-cart-01.svg" alt="Ikona 1" className="mb-6 mx-auto" />
                                        <p className="text-text tracking-[0.45px] font-extrabold text-p leading-p mb-2">Galeria <br /> Morena:</p>
                                        <p className="text-h3 leading-h3 tracking-[0.6px] font-extrabold text-secondary">1,5 km</p>
                                    </div>
                                    <div className="glass-style rounded-2xl px-4 py-8 text-center max-w-[230px] maxMd:max-w-[160px] w-full">
                                        {/* <Icon icon={`hugeicons:students`} width={72} className="mb-6 mx-auto text-secondary"/> */}
                                        <img src="/storage/icons/hugeicons_students.svg" alt="Ikona 1" className="mb-6 mx-auto" />
                                        <p className="text-text tracking-[0.45px] font-extrabold text-p leading-p mb-2">Gdański Uniwersytet Medyczny:</p>
                                        <p className="text-h3 leading-h3 tracking-[0.6px] font-extrabold text-secondary">7 km</p>
                                    </div>
                                </div>
                            </Column>
                            {/* <Column xl={10} lg={12}>
                                <Row>
                                    <Column xl={3} lg={3} md={4} className="mb-8">
                                        <div className="flex flex-wrap gap-x-10 gap-y-8">
                                            <button title="Filtrowanie według aptek" type="button" onClick={() => reloadMap('/storage/estate-maps/mapa-apteka.png')} className="flex gap-x-4 items-center text-p leading-p text-primary font-extrabold">
                                                <img src="/storage/icons/drugstore.svg" alt="Apteka ikona" />
                                                <p>Apteka</p>
                                            </button>
                                        </div>
                                    </Column>
                                    <Column xl={3} lg={3} md={4} className="mb-8">
                                        <div className="flex flex-wrap gap-x-10 gap-y-8">
                                            <button title="Filtrowanie według restauracji" type="button" onClick={() => reloadMap('/storage/estate-maps/mapa-restauracja.png')} className="flex gap-x-4 items-center text-p leading-p text-primary font-extrabold">
                                                <img src="/storage/icons/restaruants.svg" alt="Restaruracja ikona" />
                                                <p>Restaruracja</p>
                                            </button>
                                        </div>
                                    </Column>
                                    <Column xl={3} lg={3} md={4} className="mb-8">
                                        <div className="flex flex-wrap gap-x-10 gap-y-8">
                                            <button title="Filtrowanie według szkół" type="button" onClick={() => reloadMap('/storage/estate-maps/mapa-szkola.png')} className="flex gap-x-4 items-center text-p leading-p text-primary font-extrabold">
                                                <img src="/storage/icons/school.svg" alt="Szkoła ikona" />
                                                <p>Szkoła</p>
                                            </button>
                                        </div>
                                    </Column>
                                    <Column xl={3} lg={3} md={4} className="mb-8">
                                        <div className="flex flex-wrap gap-x-10 gap-y-8">
                                            <button title="Filtrowanie według komunikacji" type="button" onClick={() => reloadMap('/storage/estate-maps/mapa-komunikacja.png')} className="flex gap-x-4 items-center text-p leading-p text-primary font-extrabold">
                                                <img src="/storage/icons/railway.svg" alt="Komunikacja ikona" />
                                                <p>Komunikacja</p>
                                            </button>
                                        </div>
                                    </Column>
                                    <Column xl={3} lg={3} md={4} className="mb-8">
                                        <div title="Filtrowanie według przychodni" className="flex flex-wrap gap-x-10 gap-y-8">
                                            <button type="button" onClick={() => reloadMap('/storage/estate-maps/mapa-przychodnia.png')} className="flex gap-x-4 items-center text-p leading-p text-primary font-extrabold">
                                                <img src="/storage/icons/walkingclinic.svg" alt="Przychodnia ikona" />
                                                <p>Przychodnia</p>
                                            </button>
                                        </div>
                                    </Column>
                                    <Column xl={3} lg={3} md={4} className="mb-8">
                                        <div className="flex flex-wrap gap-x-10 gap-y-8">
                                            <button title="Filtrowanie według skleĻow" type="button" onClick={() => reloadMap('/storage/estate-maps/mapa-sklepy.png')} className="flex gap-x-4 items-center text-p leading-p text-primary font-extrabold">
                                                <img src="/storage/icons/shop.svg" alt="Sklep ikona" />
                                                <p>Sklep</p>
                                            </button>
                                        </div>
                                    </Column>
                                    <Column xl={3} lg={3} md={4} className="mb-8">
                                        <div className="flex flex-wrap gap-x-10 gap-y-8">
                                            <button title="Filtrowanie według przedszkoli" type="button" onClick={() => reloadMap('/storage/estate-maps/mapa-przedszkole.png')} className="flex gap-x-4 items-center text-p leading-p text-primary font-extrabold">
                                                <img src="/storage/icons/preschool.svg" alt="Przedszkole ikona" />
                                                <p>Przedszkole/Żłobek</p>
                                            </button>
                                        </div>
                                    </Column>
                                    <Column xl={3} lg={3} md={4} className="mb-8">
                                        <div className="flex flex-wrap gap-x-10 gap-y-8">
                                            <button title="Filtrowanie według kościołów" type="button" onClick={() => reloadMap('/storage/estate-maps/mapa-kosciol.png')} className="flex gap-x-4 items-center text-p leading-p text-primary font-extrabold">
                                                <img src="/storage/icons/church.svg" alt="Kościół ikona" />
                                                <p>Kościół</p>
                                            </button>
                                        </div>
                                    </Column>
                                </Row>
                            </Column> */}
                        </Row>
                    </>
                {/* } */}
            </Container>
        </section>
    )
}

export default InvestmentLocation;