import { Link } from "@inertiajs/react";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import EstateAImage from '@resources/estateApreview.jpg?format=webp&quality=75&w=1600;1200;1000;767;500&as=srcset&imagetools';
import EstateBImage from '@resources/estateBpreview.jpg?format=webp&quality=75&w=1600;1200;1000;767;500&as=srcset&imagetools';
import { Icon } from "@iconify/react/dist/iconify.js";

const AvailableInSell = ({ estateA, estateB }: {
    estateA: number,
    estateB: number
}) => {
    return (
        <section id="available-in-sell" className="mb-26 maxMd:mb-14">
            <Container>
                <Row>
                    <Column>
                        <h2 className="text-h2 leading-h2 text-primary font-extrabold mb-12 uppercase">Dostępne w sprzedaży</h2>
                    </Column>
                    <Column xl={6} lg={6} md={6}>
                        <img
                            srcSet={EstateAImage}
                            alt="Zdjęcie wnętrza 1"
                            className="mb-4"
                        />
                        <p className="text-h3 leading-h3 text-primary font-extrabold tracking-[0.8px] mb-4">Budynek A</p>
                        <p className="text-h2 leading-h2 text-secondary uppercase tracking-[1.2px] mb-4">
                            {estateA}
                            <span className="text-text text-h4 leading-h4 tracking-[0.6px] pl-4"> dostępnych mieszkań</span>
                        </p>
                        <Link href={route('frontend.apartments.estateA')} className="button-outline max-w-fit mt-6">
                            Dowiedz się więcej
                            <Icon icon={`basil:arrow-right-outline`} width={24} />
                        </Link>
                    </Column>
                    <Column xl={6} lg={6} md={6}>
                        <img
                            srcSet={EstateBImage}
                            alt="Zdjęcie wnętrza 1"
                            className="mb-4"
                        />
                        <p className="text-h3 leading-h3 text-primary font-extrabold tracking-[0.8px] mb-4">Budynek B</p>
                        <p className="text-h2 leading-h2 text-secondary uppercase tracking-[1.2px] mb-4">
                            {estateB}
                            <span className="text-text text-h4 leading-h4 tracking-[0.6px] pl-4"> dostępnych mieszkań</span>
                        </p>
                        <Link href={route('frontend.apartments.estateB')} className="button-outline max-w-fit mt-6">
                            Dowiedz się więcej
                            <Icon icon={`basil:arrow-right-outline`} width={24} />
                        </Link>
                    </Column>
                </Row>
            </Container>
        </section>
    )
}

export default AvailableInSell;