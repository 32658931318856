import { ReactNode } from "react";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import useMediaQuery from "@/Hooks/useMediaQuery";
import { Icon } from "@iconify/react/dist/iconify.js";

interface IconsDataInterface {
    icon: string, 
    text: ReactNode
}

const Facilities = () => {
    const isMobile = useMediaQuery();

    const iconsData: IconsDataInterface[] = [
        {
            icon: '/storage/icons/certification2.svg',
            text: <p>budynki przyjazne <br /> środowisku z certyfikatem BREEAM</p>
        },
        {
            icon: '/storage/icons/people.svg',
            text: <p>Oryginalne zaplanowane <br /> części wspólne</p>
        },
        {
            icon: '/storage/icons/storage-cells2.svg',
            text: <p>Komórki <br /> lokatorskie</p>
        },
        {
            icon: '/storage/icons/parking2.svg',
            text: <p>Podziemne <br /> hale garażowe</p>
        },
        {
            icon: '/storage/icons/bike-rack2.svg',
            text: <p>ROWEROWNIE I STOJAKI <br /> ROWEROWE NA ZEWNĄTRZ</p>
        },
        {
            icon: '/storage/icons/repair-station2.svg',
            text: <p>SAMOOBSŁUGOWE STACJE <br /> NAPRAWY ROWERÓW</p>
        },
    ];

    return(
        <section className="bg-secondary relative py-18 overflow-y-hidden mb-26 maxMd:py-16 maxMd:mb-14">
            <img loading="lazy" width={1920} height={775} src="/storage/static/kusocinskiego-text-decors.svg" alt="Dekory kusocińskiego" className="absolute left-0 -top-20 maxMd:top-0 maxMd:h-full maxMd:w-full maxMd:object-cover maxMd:object-[10%,0%] z-0"/>
            <Container>
                <Row className="justify-center">
                    <Column xl={5}>
                        <h3 className="text-h2 leading-h2 text-white font-bold mb-28 text-center uppercase maxMd:mb-24">Udogodnienia</h3>
                    </Column>
                </Row>
                {!isMobile &&
                    <Row className="justify-center gap-y-16">
                        {iconsData.map((item, i) => (
                            <Column xl={3} lg={4} md={6} className="text-center" key={i}>
                                <img src={item.icon} className="mx-auto mb-6" width={96} height={96} alt={(item.text as React.ReactElement).props.children}/>
                                <div className="text-p leading-p font-extrabold text-white uppercase">
                                    {item.text}
                                </div>
                            </Column>
                        ))}
                    </Row>
                }
                {isMobile &&
                    <Row>
                        <Column className="flex !px-0 mb-16 z-10 relative">
                            <div className="inline-flex overflow-x-scroll gap-x-4 max-w-[300px] mx-auto justify-start">
                                {iconsData.slice(0, 4).map((icon, i) => (
                                    <div key={icon.icon} className="min-w-[250px]">
                                        <img
                                            src={icon.icon}
                                            loading="lazy"
                                            className="mx-auto mb-6"
                                            width={112}
                                            height={112}
                                            alt={(icon.text as React.ReactElement).props.children}
                                        />
                                        <div className="text-p leading-p font-extrabold text-white uppercase text-center">
                                            {icon.text}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Icon icon={`oui:arrow-right`} width={32} className="text-white absolute right-4 top-[20%] z-10"/>
                        </Column>
                        <Column className="flex !px-0 z-10 relative">
                            <div className="inline-flex overflow-x-scroll gap-x-4 max-w-[310px] ml-auto justify-start">
                                {iconsData.slice(4, iconsData.length).map((icon, i) => (
                                    <div key={icon.icon} className="min-w-[250px]">
                                        <img
                                            src={icon.icon}
                                            loading="lazy"
                                            className="mx-auto mb-6"
                                            width={112}
                                            height={112}
                                            alt={(icon.text as React.ReactElement).props.children}
                                        />
                                        <div className="text-p leading-p font-extrabold text-white uppercase text-center">
                                            {icon.text}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Icon icon={`oui:arrow-right`} width={32} className="text-white absolute left-4 top-[20%] z-10"/>
                        </Column>
                    </Row>
                }
            </Container>
        </section>
    )
}

export default Facilities;