import Advantages1 from '@static/advantages-1.jpg?format=webp&quality=75&w=1040;767;500&as=srcset&imagetools';
import Advantages2 from '@static/advantages-2.jpg?format=webp&quality=75&w=1040;767;500&as=srcset&imagetools';
import Advantages3 from '@static/advantages-3.jpg?format=webp&quality=75&w=1040;767;500&as=srcset&imagetools';
import Advantages4 from '@static/advantages-4.jpg?format=webp&quality=75&w=1040;767;500&as=srcset&imagetools';
import Advantages5 from '@static/advantages-5.jpg?format=webp&quality=75&w=1040;767;500&as=srcset&imagetools';
import Advantages6 from '@static/advantages-6.jpg?format=webp&quality=75&w=1040;767;500&as=srcset&imagetools';
import { useEffect, useRef, useState } from "react";
import Swiper from "swiper";
import { SwiperProps } from "swiper/react";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import { Transition } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { register } from 'swiper/element';

register();

const PlaceAdvantages = () => {
    const [realIndex, setRealIndex] = useState(1);
    const swiperRef = useRef() as any;
    const textRefs = useRef<HTMLDivElement[]>([]);
    const [maxHeight, setMaxHeight] = useState<number | null>(null);

    const slides = [
        {
            img: Advantages1,
            text: <p>Atrakcyjne przestrzenie wspólne budują sąsiedzkie relacje</p>
        },
        {
            img: Advantages2,
            text: <p>Równowaga ekologiczna zostaje potwierdzona certyfikatem BREEAM </p>
        },
        {
            img: Advantages3,
            text: <p>Wszystko masz pod ręką: komunikację miejską, szkoły, przedszkola, szkoły i tereny zielone</p>
        },
        {
            img: Advantages4,
            text: <p>Mieszkania są funkcjonalne, łatwe w aranżacji, a oferta lokali szeroka</p>
        },
        {
            img: Advantages5,
            text: <p>Dodatkowe pomieszczenia jak komórki lokatorskie czy hala garażowa ułatwiają codzienność</p>
        },
        {
            img: Advantages6,
            text: <p>Bezpieczeństwo mieszkańców jest priorytetem, a monitoring i ochrona zapewnią Ci spokój</p>
        },
        {
            img: Advantages1,
            text: <p>Atrakcyjne przestrzenie wspólne budują sąsiedzkie relacje</p>
        },
        {
            img: Advantages2,
            text: <p>Równowaga ekologiczna zostaje potwierdzona certyfikatem BREEAM </p>
        },
        {
            img: Advantages3,
            text: <p>Wszystko masz pod ręką: komunikację miejską, szkoły, przedszkola, szkoły i tereny zielone</p>
        },
        {
            img: Advantages4,
            text: <p>Mieszkania są funkcjonalne, łatwe w aranżacji, a oferta lokali szeroka</p>
        },
        {
            img: Advantages5,
            text: <p>Dodatkowe pomieszczenia jak komórki lokatorskie czy hala garażowa ułatwiają codzienność</p>
        },
        {
            img: Advantages6,
            text: <p>Bezpieczeństwo mieszkańców jest priorytetem, a monitoring i ochrona zapewnią Ci spokój</p>
        },
    ]

    useEffect(() => {
        const swiperContainer = swiperRef.current;

        if (!swiperContainer) return;

        const params: SwiperProps = {
            speed: 500,
            // autoplay: {
            //     delay: 3000,
            // },
            centeredSlides: true,
            spaceBetween: 24,
            loop: true,
            pagination: {
                el: ".swiper-pagination-advantages",
                clickable: true,
                renderBullet: (index, className) => {
                    const actualIndex = index % 6;

                    if (index < 6) {
                        return `<div class="${className} custom-pagination-dot"></div>`;
                    }
                    return "";
                },
            },
            breakpoints: {
                1400: {
                    slidesPerView: 3.7,
                },
                1024: {
                    slidesPerView: 2.7,
                },
                768: {
                    slidesPerView: 1.5,
                }
            },
            navigation: {
                prevEl: '.advantages-prev',
                nextEl: '.advantages-next',
            },
            on: {
                realIndexChange: (swiper: Swiper) => {
                    setRealIndex(swiper.realIndex + 1);
                },
                slideChange: (swiper) => {
                    const paginationBullets = document.querySelectorAll('.swiper-pagination-advantages .custom-pagination-dot');
                    paginationBullets.forEach((bullet, bulletIndex) => {
                        if (bulletIndex === swiper.realIndex % 6) {
                            bullet.classList.add('swiper-pagination-bullet-active');
                        } else {
                            bullet.classList.remove('swiper-pagination-bullet-active');
                        }
                    });
                },
            },
        };
        Object.assign(swiperContainer, params);
        swiperContainer.initialize();
    }, [swiperRef])

    useEffect(() => {
    if (textRefs.current.length > 0) {
        const heights = textRefs.current.map((el) => el?.clientHeight || 0);
        setMaxHeight(Math.max(...heights));
    }
}, [textRefs]);

    return (
        <section id="place-advantages" className='relative mb-26 maxMd:mb-14'>
            <Container>
                <Row>
                    <Column>
                        <div className="mb-12">
                            <p className="text-primary text-p leading-p uppercase mb-4 font-extrabold">Atuty inwestycji</p>
                            <h3 className="text-secondary text-h2 leading-h2 mb-8 font-extrabold tracking-[1.2px] uppercase">Poznaj miejsce, gdzie...</h3>
                        </div>
                    </Column>
                </Row>
            </Container>
            <swiper-container ref={swiperRef} init={false} pagination className="swiper-advantages">
                {slides.map((advantage, index) => (
                    <swiper-slide
                        key={index}
                    >
                        <img
                            srcSet={advantage.img}
                            alt={`Atut ${index + 1}`}
                            className={`transition-all ease-in-out duration-500 ${(index + 1) === realIndex ? 'opacity-100' : 'opacity-30'} object-cover`}
                        />
                        <Transition
                            show={(index + 1) === realIndex}
                            enter="transition-all ease-in-out duration-500"
                            enterFrom="opacity-0 -translate-y-8"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition-all ease-in-out duration-500"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 -translate-y-8"

                        >
                            <div
                                className='mt-8 pl-4 text-text tracking-[0.45px] text-p label-p font-semibold'
                                style={{ height: maxHeight || 'auto' }}
                                ref={(el) => {
                                    if (el) {
                                        textRefs.current[index] = el;
                                    }
                                }}
                            >
                                {advantage.text}
                            </div>
                        </Transition>
                    </swiper-slide>
                ))}
            </swiper-container>
            <div className="px-20 w-full flex gap-x-4 items-center justify-center mt-6 bottom-0">
                <button className='glass-style advantages-prev py-3 px-6 rounded-[60px] transition-all ease-in-out duration-300 hover:-translate-x-2' type='button'>
                    <Icon icon={`solar:alt-arrow-down-outline`} width={40} className='text-primary rotate-90' />
                </button>
                <div className="flex gap-x-3">
                    <div className="swiper-pagination-advantages flex gap-x-3 justify-center mx-auto"></div>
                </div>
                <button className='glass-style advantages-next py-3 px-6 rounded-[60px] transition-all ease-in-out duration-300 hover:translate-x-2' type='button'>
                    <Icon icon={`solar:alt-arrow-down-outline`} width={40} className='text-primary -rotate-90' />
                </button>
            </div>
        </section>
    )
}

export default PlaceAdvantages;