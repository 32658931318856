import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import WebpImage from "../WebpImage";
import Runner from '@resources/kusocinskiego-maraton.jpg?format=webp&quality=75&w=1600;1200;1000;767;500&as=srcset&imagetools';

const ChoiceOnMedal = () => {
    return (
        <Container className="xl:mb-26 lg:mb-[250px] mb-14">
            <Row className="relative">
                <Column xl={6} lg={6}>
                    <img
                        src="/storage/static/kusocinskiego-signet.svg"
                        alt="Sygnet kusocińskiego"
                        width={225}
                        height={225}
                        className="mx-auto lg:mt-20 lg:mb-12 mt-0 lg:w-[225px] w-[155px] mb-6"
                    />
                    <div className="block lg:hidden -mx-4">
                        <WebpImage
                            width={787}
                            height={800}
                            fallback="/storage/static/kusocinskiego-triple.jpg"
                            lazy
                            className="md:h-1/2 lg:h-auto md:mx-auto lg:mx-0"
                            alt="Kusociński 3 zdjęcia"
                        />
                    </div>
                    <div className="lg:glass-style unset-glass p-12 maxMd:p-0 maxMd:mt-6 lg:absolute left-0 max-w-[1060px] w-full xl:bottom-4 lg:-bottom-[25%] static">
                        <h2 className="text-h2 leading-h2 text-primary uppercase font-extrabold mb-8 maxMd:mb-6">
                            <span className="text-secondary">Wybór</span> na medal
                        </h2>
                        <p className="text-text text-p leading-p mb-6 maxMd:mb-4 [&>strong]:text-primary [&>strong]:font-extrabold">
                            Ulica, przy której zlokalizowana jest inwestycja, nosi imię Janusza Kusocińskiego – wybitnego polskiego lekkoatlety i złotego medalisty olimpijskiego igrzysk 1932 roku.
                        </p>
                        <p className="text-text text-p leading-p mb-6 maxMd:mb-4 [&>strong]:text-primary [&>strong]:font-extrabold">
                            Unidevelopment, inspirowany ikoną sportu, tworzy osiedle na medal: budowane w sposób zrównoważony, zaprojektowane z myślą o swobodzie w aranżacji przestronnych mieszkań, pełne udogodnień oraz zwrócone ku sąsiedzkiej wspólnocie i kojącej zieleni wokół.
                        </p>
                        <p className="text-text text-p leading-p [&>strong]:text-primary [&>strong]:font-extrabold">
                            Odkryj, jak po mistrzowsku połączyliśmy funkcjonalność, bezpieczeństwo i atrakcyjny design w topowej lokalizacji, doskonale skomunikowanej z centrum Gdańska.
                        </p>
                    </div>
                </Column>
                <Column xl={5} lg={5} className="hidden lg:block ml-auto">
                    <img
                        width={787}
                        height={800}
                        loading="lazy"
                        srcSet={Runner}
                        alt="Kusociński 3 zdjęcia"
                    />
                </Column>
            </Row>
        </Container>
    )
}

export default ChoiceOnMedal;