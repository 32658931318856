import { PostModel } from "@/types/models/post";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import { Link } from "@inertiajs/react";
import { RefObject, useEffect, useRef, useState } from "react";
import { SwiperProps, SwiperRef } from "swiper/react";
import Swiper from "swiper";
import { Icon } from "@iconify/react/dist/iconify.js";
import { register } from "swiper/element";

register();

const News = ({ posts }: { posts: PostModel[] }) => {
    const swiperRef = useRef<SwiperRef>(null);
    const [realIndex, setRealIndex] = useState(1);
    const [indicatorPosition, setIndicatorPosition] = useState<string>('0%');
    const titleRefs = useRef<HTMLHeadingElement[]>([]);
    const imageRefs = useRef<HTMLDivElement[]>([]);
    const excerptRefs = useRef<HTMLDivElement[]>([]);

    const getFormat = (filename: string) => {
        return filename.split('.').pop();
    };

    useEffect(() => {
        // @ts-ignore
        if (posts) {
            const swiperContainer = swiperRef.current;
            const params: SwiperProps = {
                slidesPerView: 1.2,
                grabCursor: false,
                speed: 300,
                // autoplay: {
                //     delay: 5000,
                // },
                breakpoints: {
                    1650: {
                        slidesPerView: 4.99,
                    },
                    1024: {
                        slidesPerView: 3.99,
                    },
                    768: {
                        slidesPerView: 2.99,
                    }
                },
                spaceBetween: 24,
                loop: posts.length > 4,
                centeredSlides: false,
                navigation: {
                    prevEl: '.controlled-swiper-prev-slide',
                    nextEl: '.controlled-swiper-next-slide',
                },
                on: {
                    realIndexChange: (swiper: Swiper) => {
                        setRealIndex(swiper.realIndex + 1);

                    },
                },
            };
            // @ts-ignore
            Object.assign(swiperContainer, params);
            // @ts-ignore
            swiperContainer.initialize();
        }
    }, [swiperRef])

    useEffect(() => {
        if (realIndex == 1) {
            setIndicatorPosition('0%');
        } else {
            setIndicatorPosition((realIndex / posts.length) * 100 - 10 + '%')
        }
    }, [indicatorPosition, realIndex])

    useEffect(() => {
        if(titleRefs.current.length > 0) {
            const heights = titleRefs.current.map((el) => el.clientHeight);
            const maxHeight = Math.max(...heights);
            
            titleRefs.current.forEach((el) => {
                el.style.height = `${maxHeight}px`;
            });
            
        }

        if(imageRefs.current.length > 0) {
            const heights = imageRefs.current.map((el) => {
                return el.children[0].children[el.children[0].children.length - 1].clientHeight
            });
            const maxHeight = Math.max(...heights);
            
            imageRefs.current.forEach((el) => {
                el.style.minHeight = `${maxHeight}px`;
            });
        }

        if(excerptRefs.current.length > 0) {
            const heights = excerptRefs.current.map((el) => {
                return el.children[0].clientHeight
            });
            const maxHeight = Math.max(...heights);
            
            excerptRefs.current.forEach((el) => {
                el.style.height = `${maxHeight}px`;
            });
        }
    }, [titleRefs, imageRefs, excerptRefs])

    const LimitedWordsDiv = ({ htmlContent , wordLimit }: {htmlContent: string; wordLimit: number}) => {
        const limitWords = (content: string, limit: number) => {
            const words = content.split(/\s+/);
            const limitedWords = words.slice(0, limit);
            return limitedWords.join(' ') + '...';
        };

        const limitedContent = limitWords(htmlContent, wordLimit);

        return <div className="text-p text-text leading-p mb-8" dangerouslySetInnerHTML={{ __html: limitedContent }} />;
    };

    return (
        <section className="mb-26 relative maxMd:mb-14">
            <Container>
                <Row>
                    <Column>
                        <h4 className="text-h2 leading-h2 uppercase font-extrabold text-primary mb-8">Aktualności</h4>
                    </Column>
                </Row>
            </Container>
            <div className="h-[705px] w-20 absolute left-0 top-[70px] z-10 maxMd:hidden">
                <button type="button" className="absolute left-8 top-1/2 controlled-swiper-prev-slide" title="Poprzedni slajd">
                    <Icon icon={`oui:arrow-left`} className="text-primary" width={40} height={40} />
                </button>
            </div>
            <div className="ml-auto max-w-[92%] maxMd:pl-4">
                <swiper-container ref={swiperRef as RefObject<any>} init={false} navigation={true}>
                    {posts.map((slide, i) => (
                        <swiper-slide key={i}>
                            <div
                                ref={(el) => {
                                    if (el) {
                                        imageRefs.current[i] = el;
                                    }
                                }}
                            >
                                <picture>
                                    <source
                                        srcSet={`${slide.photo.replace(/\.[^.]+$/, '')}.webp`}
                                        type="image/webp"
                                    />
                                    <source
                                        srcSet={`${slide.photo.replace(/\.[^.]+$/, '')}.${getFormat(slide.photo)}`}
                                        type={`image/${getFormat(slide.photo)}`}
                                    />
                                    <img 
                                        className="w-full h-auto object-cover object-left max-h-[180px]" 
                                        src={slide.photo} 
                                        alt={slide.name} 
                                    />
                                </picture>
                            </div>
                            <p className="uppercase text-label leading-label mt-7 mb-1 text-secondary font-extrabold">Data dodania: {slide.created_at}</p>
                            <h4 
                                className="text-h4 leading-h4 uppercase text-primary font-extrabold mb-6"
                                ref={(el) => {
                                    if (el) {
                                        titleRefs.current[i] = el;
                                    }
                                }}
                            >
                                {slide.name}
                            </h4>
                            <div
                                ref={(el) => {
                                    if (el) {
                                        excerptRefs.current[i] = el;
                                    }
                                }}
                            >
                                <LimitedWordsDiv htmlContent={slide.excerpt} wordLimit={9} />
                            </div>
                            <Link href={route('frontend.news.single', { slug: slide.slug })} className="flex items-center button-outline mt-8" title={`Czytaj dalej artykuł ${slide.name}`}>
                                Czytaj dalej
                                <Icon icon="formkit:arrowright" width={24} className="mt-1 ml-2" />
                            </Link>
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div className="bg-whiteOverlay h-[705px] w-[170px] absolute right-0 top-[70px] z-10 maxMd:hidden">
                    <button type="button" className="absolute right-10 top-1/2 controlled-swiper-next-slide" title="Kolejny slajd">
                        <Icon icon={`oui:arrow-right`} className="text-primary" width={40} height={40} />
                    </button>
                </div>
            </div>
            <Container>
                <Row>
                    <Column>
                        <div className="indicator-wrapper relative h-[6px] w-full border-t-[2px] border-[#D57F20] mt-8 overflow-x-hidden maxMd:mt-0">
                            <div style={{ left: indicatorPosition, width: (posts.length) * 5 + '%' }} className={`h-2 transition-all ease-in-out duration-300 bg-[#D57F20] absolute -top-[2px]`}></div>
                        </div>
                    </Column>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Column>
                        <Link preserveScroll={false} preserveState={false} href={route('frontend.news')} title="Przejdź do listy wpisów" className="button-primary max-w-[300px] mx-auto mt-8">
                            Przejdź do listy wpisów
                        </Link>
                    </Column>
                </Row>
            </Container>
        </section>
    )
}

export default News;