import useMediaQuery from "@/Hooks/useMediaQuery";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import { Icon } from "@iconify/react/dist/iconify.js";

const IconsWithTexts = () => {
    const isMobile = useMediaQuery();

    const iconsData = [
        {
            img: '/storage/icons/silhouette2.svg',
            text: <p>atrakcyjne układy mieszkań</p>
        },
        {
            img: '/storage/icons/sunbed2.svg',
            text: <p>Mieszkania z balkonami <br /> lub tarasami</p>
        },
        {
            img: '/storage/icons/park2.svg',
            text: <p>Ogródki w mieszkaniach <br /> parterowych</p>
        },
        // {
        //     img: '/storage/icons/apartment.svg',
        //     text: <p>1-5 pokoi: 26-130m<sup>2</sup></p>
        // },
        {
            img: '/storage/icons/cctv-camera2.svg',
            text: <p>Monitoring i ochrona</p>
        },
        {
            img: '/storage/icons/window2.svg',
            text: <p>Parapety Z konglomeratu</p>
        },
        {
            img: '/storage/icons/brick-wall2.svg',
            text: <p>Ciekawie zaprojektowana <br /> elewacja z jasną okładziną</p>
        },
    ]
    if (isMobile) {
        return (
            <section className="pt-14 mb-14 overflow-x-scroll">
                <Container>
                    <Row>
                        <Column className="flex !px-0 relative">
                            <div className="inline-flex overflow-x-scroll gap-x-4 max-w-[280px] mr-auto">
                                {iconsData.slice(0, 3).map((icon, i) => (
                                    <div key={icon.img} className="min-w-[250px]">
                                        <img
                                            src={icon.img}
                                            loading="lazy"
                                            className="mx-auto"
                                            width={112}
                                            height={112}
                                            alt={`Ikona ${i+1}`}
                                        />
                                        <div className="text-primary text-p leading-p text-center mt-6 font-extrabold uppercase">
                                            {icon.text}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Icon icon={`oui:arrow-right`} width={32} className="text-primary absolute right-4 top-[20%] z-10"/>
                        </Column>
                        <Column className="flex !px-0 relative">
                            <div className="inline-flex overflow-x-scroll gap-x-4 max-w-[280px] ml-auto">
                                {iconsData.slice(3, iconsData.length).map((icon, i) => (
                                    <div key={icon.img} className="min-w-[250px]">
                                        <img
                                            src={icon.img}
                                            loading="lazy"
                                            className="mx-auto"
                                            width={112}
                                            height={112}
                                            alt={`Ikona ${i+1}`}
                                        />
                                        <div className="text-primary text-p leading-p text-center mt-6 font-extrabold uppercase">
                                            {icon.text}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Icon icon={`oui:arrow-right`} width={32} className="text-primary absolute left-4 top-[20%] z-10"/>
                        </Column>
                    </Row>
                </Container>
            </section>
        )
    }

    return (
        <section className="py-18 mb-18">
            <Container>
                <Row className="gap-y-16 justify-center">
                    {iconsData.map((icon, i) => (
                        <Column xl={4} lg={4} md={4} key={i}>
                            <div>
                                <img
                                    src={icon.img}
                                    loading="lazy"
                                    className="mx-auto"
                                    width={112}
                                    height={112}
                                    alt={`Ikona ${i+1}`}
                                />
                                <div className="text-primary text-p leading-p text-center mt-6 font-extrabold uppercase">
                                    {icon.text}
                                </div>
                            </div>
                        </Column>
                    ))}
                </Row>
            </Container>
        </section>
    )
}

export default IconsWithTexts;